import * as React from 'react';
// import ReactStopwatch from 'react-stopwatch';

class Stopwatch extends React.Component {

  shouldComponentUpdate() {
    return false;
  }
  render() {
    return (
      // <ReactStopwatch
      //   seconds={0}
      //   minutes={0}
      //   hours={0}
      //   onCallback={() => console.log('Finish')}
      //   render={({ formatted, hours, minutes, seconds }) => {
      //     return (
      //       <span>

      //         {formatted}

      //       </span>
      //     );
      //   }}
      // />
      '00:00:00'
    )
  }

};

export default Stopwatch;